  .react-calendar-timeline .rct-header-root {
    background: #4682b4;
  }

  .react-calendar-timeline .rct-today-marker {
    background: #4682b4;
  }
    .react-calendar-timeline .rct-items .rct-item.book {
      background-color:  lightcyan; 
      color: #000000 ;   }
    .react-calendar-timeline .rct-items .rct-item.visit {
      background-color:  steelblue; 
      color: #ffffff;    }
    .react-calendar-timeline .rct-items .rct-item.pay {
      background-color: darkblue;
      color: #ffffff;    }
    .react-calendar-timeline .rct-items .rct-item.sum {
      background-color:  dimgray;
      color: #ffffff;    }
    .react-calendar-timeline .rct-items .rct-item.net {
      background-color:  lemonchiffon;
      color: #000000;    }
    .react-calendar-timeline .rct-items .rct-item.cancel {
      background-color:  lavenderblush;
      color: #000000;    }

    .react-calendar-timeline .rct-items .rct-item.timeoff {  /* 国民の休日 */
      background-color:  #E18F9B; 
      color: #000000 ;   }

    .react-calendar-timeline .rct-items .rct-item.other {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  white; 
      color: #000000 ;    }
    .react-calendar-timeline .rct-items .rct-item.request {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  honeydew;  /* #CAE3BF #99ff99;  #6aa84f; */
      color: #000000;    }
    .react-calendar-timeline .rct-items .rct-item.requestoff {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  lavenderblush; /* #99ff99;  #6aa84f; */
      color: #000000;    }
    .react-calendar-timeline .rct-items .rct-item.create {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  #CAE3BF; 
      color: #000000 ;    }
    .react-calendar-timeline .rct-items .rct-item.createoff {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  lavenderblush; /* #99ff99;  #6aa84f; */
      color: #000000;    }
    .react-calendar-timeline .rct-items .rct-item.release {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  #5C7D57; /* rgba(0,0,0,0); */ 
      color: #FFFFFF;    }
    .react-calendar-timeline .rct-items .rct-item.releaseoff {
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color:  #E18F9B; /* #99ff99;  #6aa84f; */
      color: #000000;    }
    .react-calendar-timeline .rct-items .rct-item.arrive {
/*      border: 1px solid rgb(0, 200, 0); /* 境界線を変更する */
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color: #D3BD9F; /* #00ff00; */  /* #274e13;*/
      color: #FFFFFF;    }
    .react-calendar-timeline  .rct-items .rct-item.leave {
/*      border: 1px solid rgb(0, 200, 0); /* 境界線を変更する */
      height: 20px !important; /* 高さを20pxに設定 */
      line-height: 20px !important; /* 高さを20pxに設定 */
      background-color: #7D6C56; /* #00ff00; */  /* #274e13;*/
      color: #000000;    }

/*
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-2 {
    background: rgba(256, 246, 225, 0.3);
}      
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6 {
    background: rgba(225, 246, 256, 0.3);
}      
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(256, 225, 246, 0.3);
}      
*/

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-2 {
    background: rgba(255, 255, 240, 0.3);  /* ivory 火曜日 */
}      
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6 {
    background: rgba(240, 255, 255, 0.3);  /* azure 土曜日 */
}      
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(255, 240, 245, 0.3);  /* lavenderblush 日曜日 */
}

/* 勤怠管理に追加予定 2022/03/25 * /
.react-calendar-timeline .rct-items .rct-item .shiftBlock {
    background-color: #00D1BD!important; border: none; z-index: 40;
}
*/

/* Booking.js DatePicker 日曜日、土曜日 */ 
.react-datepicker__day--weekend {
  color:red;
}
.react-datepicker__day--outside-month {
  opacity:0.5;
}


.sunday-text {
  color:red;
}

/* Booking DatePicker 土曜日 */ 
.saturday-text {
  color:blue;
}

/* Booking DatePicker 国民の休日 */ 
.holiday-text {
  color:deeppink;
}