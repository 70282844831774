body {
  counter-reset: sheet; /* カウンタの初期化 */
}
.sheet::before {
  position: absolute;
  top: 0;
  content: "";
}
.sheet::after {
  position: absolute;
  bottom: 0;
  right: 0;
  counter-increment: sheet;
  content: "ページ " counter(sheet);
  line-height:0;
}
