html {
  touch-action: manipulation;

}

body {
    font-family: sans-serif;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group {
    margin-bottom: 7px;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0px;
    white-space:nowrap;
/*    word-wrap: normal; */
}

.alignRight {
    text-align:right;
}

.alignLeft {
    text-align:left;
}

.alignCenter {
    text-align:center;
}

.panel-body {
    padding: 0;
}

.popover {
    max-width: 400px;
}

.popover-content {
  overflow-y : scroll;
  height: 400px;  
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 2px;
    padding-left: 2px;
}

.react-select-original {
    padding: 0px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    z-index: 100 !important;
}

.react-select-original:focus {
    border-color: skyblue;   /* 枠線の色 */
    background-color: #ffc;  /* 背景色 */
}

.react-select-original:focus {
    outline: 2px skyblue solid;           /* ※ブラウザが標準で付加する線にしたいとき */
}

/*****
.react-select__menu {
    z-index: 999 !important;
}
*****/
/***
.input-group-append {
    z-index: 0 !important;
}
***/

/***
.Select.is-open {
	z-index: 9999 !important;
}

.Select-menu-outer {
  z-index: 999 !important;
}
***/
.control--menu-is-open {
	z-index: 100 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  width: 330px;
}

/*****
.dropdown-menu {
	z-index: 9999 !important;
	position: absolute;
}
****/
.react-datepicker__dropdown {
  z-index: 9999; /* モーダルウィンドウより上に表示 */
}

.react-datepicker-wrapper {
    flex-basis:auto;
    flex-grow:1;
    flex-shrink:1;
    flex:10px;
}

.react-grid-Header .react-grid-HeaderCell {
    background-color: #ff7f50;  /* オレンジ */
    background-color: #4682b4;
    color: #ffffff;
}

/* react-data-grid 選択されたカラムの枠を消した */
.react-grid-Main .react-grid-Grid .react-grid-Canvas .rdg-selected {
    border: 0px;
}

.modal-header{
    padding-top: 10px;
    padding-bottom: 5px;
}
.modal-title{
    width:100%;
}
.modal-body {
    padding-top: 10px;
    padding-bottom: 5px;
}
.modal-footer {
    padding-top: 5px;
    padding-bottom: 10px;
}

.form-group {
    margin-bottom: 6px;
}


/**************************
.btn-primary {
    background-color: steelblue;
    border-color: steelblue;
}

.btn-success {
    background-color: #47b578; 
    border-color: #47b578;
}

.btn-danger {
    background-color: #db4260; 
    border-color: #db4260;
}
***************************/

.card-deck .card {
    flex: 1 0;
    margin-right: 5px;
    margin-bottom: 0;
    margin-left: 5px;
}

/* bootstrap v9.0 */
.mb-3 {
    margin-bottom: 7px!important;
}
.text-right {
    text-align:right;
}